<template>
  <div>
    <b-img
      class="w-100"
      :src="imgBg"
      alt="logo"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      // eslint-disable-next-line global-require
      imgBg: require('@/assets/images/all/home.jpg'),
    }
  },
}
</script>
